import {
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { Component } from "react";
import ResponsiveAppBar from "../components/navbar";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Footer from "../components/footer";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Contact from "../components/contact";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import ReactDOM from "react-dom";
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 600,
            lg: 900,
            xl: 1200,
        },
    },
});

const styles = (theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            padding: "1rem",
        },
        backgroundImage: 'url("images/1.jpg")',
        margin: "0rem",
        backgroundSize: "cover",
        backgroundPositiony: "bottom",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        color: "white",
        height: "100vh",
        padding: "13rem",
        paddingTop: "10rem",
    },

    card: {
        borderRadius: "60%",
        padding: "0rem",
    },
    cardAbout: {
        [theme.breakpoints.down("sm")]: {
            height: "200vh",
            padding: "2rem",
            maxHeight: "53vh",
            minHeight: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minHeight: "100%",
            borderRadius: "5%",
            padding: "2rem",
            backgroundColor: "white",
            color: "#164558",
            maxHeight: "40vh",
        },
    },
    about: {
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            padding: "3rem",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem",
            paddingTop: "5rem",
        },
        backgroundColor: "#E7EBF1",
    },
    text: {
        fontSize: "1rem",
        color: "#164558",
    },
    about1: {
        [theme.breakpoints.down("sm")]: {
            padding: "3rem",
            height: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem",
            paddingTop: "5rem",
        },

        backgroundColor: "white",
    },
    contact: {
        [theme.breakpoints.down("sm")]: {
            padding: "3rem",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "5rem",
            paddingTop: "5rem",
        },

        backgroundColor: "white",
    },
});

function Home({ classes }) {
    return (
        <div>
            <ResponsiveAppBar />
            <Grid className={classes.root} container alignItems={"center"}>
                <Grid item>
                    <div>
                        <p
                            style={{
                                fontSize: "2rem",
                                fontWeight: "bold",
                            }}
                        >
                            Sürdürülebilir, güvenilir ve doğayla barışık bir tedarik yapısı ve
                            sağlam temellere dayanan bir ticari anlayışı temsil ediyoruz.
                        </p>
                        <p>
                            Voltera 15 yılı aşkın deneyimli ekibi ile güven ve kalite
                            bilinciyle çalışarak elektrik sektörüne hizmet vermektedir.
                        </p>
                    </div>
                </Grid>
            </Grid>

            <Grid
                id="about"
                className={classes.about1}
                spacing={2}
                container
                alignItems={"center"}
            >
                <Grid container>
                    {" "}
                    <Typography
                        sx={{
                            fontSize: 26,
                            color: "#164558",
                            fontWeight: "bold",
                            marginBottom: "4rem",
                        }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Hakkımızda
                    </Typography>
                </Grid>
                <Grid container alignItems={"center"}>
                    {" "}
                    <Grid item md={6} xs={12}>
                        <img
                            src="images/logo3.png"
                            alt="elektrik"
                            style={{ width: "50%" }}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Typography className={classes.text} variant="body2">
                            Voltera BT, bir enerji şirketi olarak, müşterileri ve iş ortakları
                            için uygun maliyetli, sürdürülebilir ve güvenli enerji çözümleri
                            sunmaktadır. Şirketin bu prensiplerine sadık kalması, müşterileri
                            için bir kalite garantisidir. Piyasa düzenlemeleri kapsamında
                            yürüttüğü toptan satış ve doğrudan nihai tüketicilere enerji
                            tedariğinin yanı sıra, yenilenebilir enerji kaynaklarından enerji
                            üreterek, hem ülke, hem dünya için çalışmaktadır. Tüm bunlara ek
                            olarak müşterilerinin her türlü enerji yönetimini devralarak
                            verdiği danışmanlık hizmetleri ile müşterileri bir iş ortağı
                            haline getirmektedir. Bu kapsamlı yaklaşım, Voltera'nın iş
                            ortaklarının tüm enerji ihtiyaçlarını tek bir çatı altında
                            karşılamasını sağlar.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                id="faaliyet"
                className={classes.about}
                spacing={2}
                container
                alignItems={"center"}
            >
                <Grid container alignItems={"center"}>
                    {" "}
                    <Typography
                        sx={{ fontSize: 26, color: "#164558", fontWeight: "bold" }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Faaliyet Alanlarımız
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card className={classes.cardAbout}>
                        <CardContent className={classes.cardAbout}>
                            <img
                                src="images/elektrik.png"
                                alt="elektrik"
                                style={{ width: "70px", height: "70px" }}
                            />
                            <Typography
                                sx={{ fontSize: 20, color: "#164558", fontWeight: "bold" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Elektrik Ticareti
                            </Typography>

                            <Typography variant="body2">
                                Son Kaynak Tedarik Tarifesine dahil tüm elektrik kullanıcılar
                                için kazançlı ve sürdürülebilir teklifler ve aktif abone
                                hizmetleri sunarak, uzun soluklu iş ortaklığı amaçlanmaktadır.
                                Teklif almak için lütfen  <a href="#contact" style={{ textDecoration: 'none' }} >tıklayınız  </a>

                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className={classes.cardAbout}>
                        <CardContent className={classes.cardAbout}>
                            <img
                                src="images/energy.png"
                                alt="elektrik"
                                style={{ width: "70px", height: "70px" }}
                            />

                            <Typography
                                sx={{ fontSize: 20, color: "#164558", fontWeight: "bold" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Enerji Danışmanlığı
                            </Typography>
                            <Typography variant="body2">
                                Enerji yönetimi kapsamlı, detaylı ve güncel mevzuat takibi
                                gerektiren bir süreçtir. Bu bağlamda aboneleri birer iş ortağına
                                dönüştürerek fatura, fiyat, güneş paneli kurumlu, teklif
                                süreçleri gibi pek çok noktada kesintisiz bir danışmanlık
                                hizmeti alabilirler.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card className={classes.cardAbout}>
                        <CardContent className={classes.cardAbout}>
                            <img
                                src="images/santral.png"
                                alt="elektrik"
                                style={{ width: "70px", height: "70px" }}
                            />
                            <Typography
                                sx={{ fontSize: 20, color: "#164558", fontWeight: "bold" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Santral Yönetimi
                            </Typography>
                            <Typography variant="body2">
                                15 yılı aşkın tecrübeli ekibiyle Voltera, GES, RES ve HES
                                yönetiminde önemli bir bilgi birikim ve know-how’a sahiptir. Bu
                                bağlamda santrallerin GÖP, DGP, GİP işlemleri ve genel
                                uzlaştırma yönetimleri konusunda kapsamlı bir destek gücüne
                                sahiptir.
                            </Typography>{" "}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid
                id="contact"
                className={classes.contact}
                spacing={2}
                container
                alignItems={"center"}
            >
                <Grid container>
                    {" "}
                    <Typography
                        sx={{
                            fontSize: 26,
                            color: "#164558",
                            fontWeight: "bold",
                            marginBottom: "4rem",
                        }}
                        color="text.secondary"
                        gutterBottom
                    >
                        İletişim
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Contact />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {" "}
                        <Grid container alignItems={"center"}>
                            {/* <Grid alignItems={'center'} container style={{ marginBottom: '1rem' }}  >
                            <Grid item xs={3} md={1} >
                                <LocalPhoneIcon />
                            </Grid>
                            <Grid item xs={9} >
                                <Grid container> <Typography className={classes.text} > Telefon</Typography></Grid>
                                <Grid container> <Typography className={classes.text}>+90 212 951 0100</Typography> </Grid>
                            </Grid>
                        </Grid> */}

                            <Grid container style={{ marginBottom: "1rem" }}>
                                <Grid item xs={2} md={1}>
                                    <EmailIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Grid container>
                                        <Grid item>   <Typography className={classes.text}>Mail:</Typography></Grid>
                                        <Grid item xs={4} >   <Typography className={classes.text}>
                                            {" "}
                                            bilgi@voltera.com.tr{" "}
                                        </Typography></Grid>


                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={2} md={1}>
                                    <LocationOnIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Grid container>
                                        <Grid item >
                                            {" "}
                                            <Typography className={classes.text}>Adres:</Typography>
                                        </Grid>
                                        <Grid item xs={5} >
                                            {" "}
                                            <Typography className={classes.text}>
                                                Cumhuriyet Mah. Fatih Sultan Mehmet Blv. A Blok-15 No:
                                                41 A Nilufer/ Bursa{" "}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Footer />
        </div>
    );
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Home);
