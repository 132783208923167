import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from "@material-ui/core";

const useStyles = makeStyles({
    textfiled: {
        marginBottom: "1rem !important",
    },
});
const Contact = () => {
    const form = useRef(null);
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_q8ax5xq",
                "template_k1i4g8g",
                form.current,
                "j8_AgaqD2ZaSRxtJR"
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
    };
    const classes = useStyles();

    return (
        <section>
            <div className="container">
                <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="--form-control --card --flex-center --dir-column"
                >
                    <Grid container spacing={2}>
                        <Grid item >  <Grid container>
                            <TextField
                                className={classes.textfiled}
                                type="text"
                                placeholder="Adınız"
                                name="user_name"
                                required
                                id="outlined-basic"
                                label="Adınız"
                                variant="outlined"
                            />
                        </Grid>
                            <Grid container>
                                <TextField
                                    className={classes.textfiled}
                                    type="email"
                                    placeholder="Email"
                                    name="user_email"
                                    required
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid container >
                                <TextField
                                    className={classes.textfiled}
                                    type="text"
                                    placeholder="Konu"
                                    name="subject"
                                    required
                                    id="outlined-basic"
                                    label="Konu"
                                    variant="outlined"
                                />{" "}
                            </Grid></Grid>
                        <Grid item>   <Grid container>
                            {" "}
                            <textarea name="message" cols="30" rows="12"></textarea>
                        </Grid></Grid>
                    </Grid>



                    <Button type="submit" className="--btn --btn-primary" style={{ position: 'inherit', backgroundColor: '#164558', margin: '1rem', color: 'white', textTransform: 'none' }} variant="contained">Gönder</Button>


                </form>
            </div>
        </section>
    );
};
export default Contact;
